@media (max-width: 1230px) {
    .banner-img img {
        height: 200px;
    }
}

@media (max-width: 992px) {
    .header .header-center, .header .header-right {
        display: none;
    }
    .menu-toggle, .mobile-header {
        display: flex;
    }
}

@media (max-width: 991px) {
    .banner-img {
        display: none;
    }
}

@media (max-width: 768px) {
    .ad-details {
        flex-direction: column;
    }
    .ad-item-details ul li {
        margin-right: 0;
        margin: 0 5px;
    }
}

@media (max-width: 500px) {
    .header-center {
        height: fit-content;
    }
    .header-center-search {
        flex-wrap: wrap;
        height: fit-content;
    }
    .header-center .dropdown, .header-center .dropdown-btn {
        width: 100%;
        border-bottom: 1px solid #94A3B8;
    }
    .header-center .dropdown-btn {
        justify-content: space-between;
        border-radius: 12px;
        border: none;
    }
    .header-center input {
        border-radius: 12px;
        border: none;
        height: 36px;
    }
    .mobile-header.active {
        height: 155px;
    }
    .header {
        padding: 0 20px;
    }
    .categories-container {
        padding: 12px 20px;
    }
    .banner-link {
        margin-bottom: 40px;
    }
    .banner-heading {
        font-size: 3.5rem;
    }
}