@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

::selection {
  background-color: #059669;
  color: #000000;
}

body {
  background-color: #f8f4f4;
}

a {
  text-decoration: none;
}

.font-sora {
  font-family: "Sora", sans-serif;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.header-container {
  background-color: #ffffff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
}

.header-center {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #059669;
  border-radius: 12px;
  border: 2px solid #059669;
}

.header-center-search {
  height: 36px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 12px;
}

.header-center .dropdown-btn {
  height: 36px;
  border: none;
  outline: none;
  color: #525252;
  padding: 0 15px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  border-radius: 12px 0 0 12px;
  background-color: #ffffff;
}

.header-center .dropdown-btn img {
  height: 6px;
  margin-left: 10px;
}

.header-center .dropdown-menu {
  padding: 0;
}

.header-center .dropdown-menu li {
  cursor: pointer;
  padding: 5px 10px;
  transition: 0.5s;
}

.header-center .dropdown-menu li:hover {
  background-color: #f1f2f6;
}

.header-center input {
  height: 25px;
  border: none;
  outline: none;
  border-left: 1px solid #94a3b8;
  padding: 0 10px;
  margin-right: 10px;
  font-size: 0.9rem;
}

.header-center input::placeholder {
  color: #94a3b8;
  font-weight: 400;
}

.header-search-submit {
  background: none;
  outline: none;
  border: none;
  height: 36px;
  width: 50px;
}

.header-search-submit img {
  height: 18px;
}

.header-right {
  display: flex;
}

.header-navlink {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #059669;
  color: #059669;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: 0.5s;
  font-size: 1.1rem;
}

.header-navlink img {
  height: 25px;
}

.header-navlink:hover {
  background-color: #059669;
  color: #ffffff;
}

/* header-search-card Style ADDED BY ALEX*/
.custom-dropdown .custom-notifications {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 200px;
  overflow-y: scroll;
  border-bottom: 1px solid #efefef;
}
.custom-dropdown .custom-notifications > li {
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}

.custom-notifications .unread {
  list-style: none;
}
.pro-img {
  width: 40px;
  height: 40px;
}
/* header-search-card Style ADDED BY ALEX END*/

.cart-navlink {
  position: relative;
}

.cart-navlink div {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  color: #ffffff;
  background-color: #ff5347;
  font-size: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.login-navlink {
  height: 40px;
  border-radius: 10px;
  background-color: #059669;
  border: 1px solid #059669;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: 0.5s;
}

.login-navlink:hover {
  background-color: #ffffff;
  color: #059669 !important;
}

.mobile-header {
  position: absolute;
  top: 96px;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  overflow: hidden;
  z-index: 9;
}

.mobile-header.active {
  height: 120px;
}

.mobile-header .header-center {
  margin: 10px 0 20px 0;
}

.menu-toggle {
  height: 20px;
  cursor: pointer;
  width: 40px;
  display: none;
  justify-content: space-between;
  flex-direction: column;
}

.menu-toggle div {
  width: 100%;
  height: 2px;
  background-color: #000000;
  transition: 0.5s;
}

.menu-toggle.active .line1 {
  transform: rotate(-45deg) translateY(13px);
}
.menu-toggle.active .line3 {
  transform: rotate(45deg) translateY(-13px);
}
.menu-toggle.active .line2 {
  opacity: 0;
  transform: translateX(10px);
}

.categories-container {
  border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb;
  padding: 12px 30px;
}

.categories {
  display: flex;
  overflow-x: scroll;
}

.categories::-webkit-scrollbar {
  display: none;
}

.category {
  color: #525252;
  border: 1px solid #525252;
  border-radius: 6px;
  font-size: 0.9rem;
  padding: 0 10px;
  transition: 0.5s;
  white-space: nowrap;
}

.category:not(:last-child) {
  margin-right: 10px;
}

.category:hover {
  color: #059669;
  border-color: #059669;
}

.banner {
  background-image: url(./assets/images/banner.svg);
  background-size: cover;
  background-position: center;
}

.banner-heading {
  color: #ffffff;
  font-weight: 300;
  font-size: 4rem;
  margin-top: 40px;
}

.banner-link {
  height: 40px;
  background-color: #059669;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 30px;
  border-radius: 10px;
  margin: 30px 0 70px 0;
}

.banner-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}

.banner-img img {
  height: 300px;
}

.products-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.products {
  margin-top: 70px;
}

.products-heading h5 {
  font-size: 1.7rem;
  font-weight: 600;
  color: #1e293b;
}

.products-heading a {
  color: #059669;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  transition: 0.5s;
}

.products-heading a:hover {
  border-color: #059669;
}

.products-item {
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 20px;
}

.product-img {
  width: 100%;
  height: 100%;
  max-height: 200px !important;
  object-fit: cover;
  transition: 0.5s;
  min-height: 345px !important;
}

.product-img-container:hover img {
  transform: scale(1.1);
}

.product-text {
  padding: 10px;
}

.product-text-top {
  margin-bottom: 5px;
}

.product-text-top,
.product-text-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-text-bottom .desc {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-trancate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-text-top h6 {
  margin-bottom: 0;
  font-weight: 600;
}

.product-text-top h6 a {
  color: #1e293b;
}

.product-text-top div {
  display: flex;
  align-items: center;
  color: #475569;
}

.product-text-top div i {
  margin-right: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 3px;
}

.product-text-bottom h6 {
  margin-bottom: 0;
  color: #1e293b;
  font-weight: 600;
}

.product-text-bottom div {
  color: #475569;
  font-size: 0.8rem;
}

.cat-heading {
  font-size: 1.7rem;
  font-weight: 600;
  text-align: center;
  color: #1e293b;
}

.cat-card {
  width: 100%;
  display: flex;
  align-items: flex-start;
  transition: 0.5s;
  border-radius: 10px;
  margin-top: 30px;
}

.cat-card:hover {
  box-shadow: 0 0.5rem 1rem #00000026;
}

.cat-img {
  width: 100px;
  height: 100px;
  /* margin-right: 10px; */
  display: block;
}

.cat-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.cat-text {
  flex: 1;
}

.cat-name {
  color: #1e293b;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.cat-name:hover {
  color: #1e293b;
}

.cat-text p {
  color: #475569;
  margin-bottom: 0;
  font-size: 0.9rem;
  cursor: default;
}

.brands-heading {
  font-size: 1.7rem;
  font-weight: 600;
  color: #1e293b;
}

.brands-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.brand {
  text-align: center;
  margin-top: 20px;
}

.brand:not(:last-child) {
  margin-right: 20px;
}

.brand-top {
  height: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #475569;
  background-color: #ffffff;
  border: 1px solid #475569;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 0.9rem;
  transition: 0.5s;
}

.brand-top span {
  font-size: 0.7rem;
  line-height: 7px;
  margin-bottom: 5px;
}

.brand-top:hover {
  border-color: #059669;
  color: #059669;
}

.load-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 50px 0;
}

.load-more a {
  height: 45px;
  width: fit-content;
  color: #ffffff;
  border: 1px solid #059669;
  border-radius: 5px;
  background-color: #059669;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  font-weight: 600;
  transition: 0.5s;
}

.load-more a:hover {
  box-shadow: 0 0.5rem 1rem #00000026;
  background-color: #ffffff;
  color: #059669;
}

.footer {
  width: 100%;
  background-image: url("./assets/images/footerBg.svg");
  background-size: cover;
}

.footer * {
  color: #ffffff;
}

.footer-left h5 {
  margin-top: 20px;
}

.footer-links h5 {
  margin-bottom: 15px;
}

.footer-links a {
  display: block;
  margin-bottom: 15px;
  font-size: 0.9rem;
  transition: 0.5s;
}

.footer-links a:hover {
  color: #ffffff;
  transform: translateX(5px);
}

.footer-social {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-social-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.footer-social-img img {
  height: 25px;
  transition: 0.5s;
}

.footer-social-img img:hover {
  transform: scale(1.1);
}

.footer-social-img a:not(:last-child) {
  margin-right: 15px;
}

.footer-copy {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 15px;
  border-top: 1px solid #ffffff;
  font-weight: 300;
}

.footer-copy p {
  margin-bottom: 15px;
}

.legal-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.legal-links a {
  margin-bottom: 15px;
}

.legal-links a:not(:last-child) {
  margin-right: 25px;
}

.legal-links a:hover {
  color: #ffffff;
}

.preview-img {
  width: 100%;
  position: relative;
}

.preview-img img {
  width: 100%;
  height: 100%;
  max-height: 600px !important;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.preview-img span {
  position: absolute;
  top: 40px;
  left: 0;
  color: #ffffff;
  background-image: url("./assets/icons/arrow.svg");
  padding: 10px 40px;
  background-size: cover;
  background-position: right;
}

.p-imgs {
  width: 100%;
  display: flex;
  margin-top: 15px;
  overflow-x: scroll;
}

.p-imgs::-webkit-scrollbar {
  display: none;
}

.p-imgs img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.p-imgs img:not(:last-child) {
  margin-right: 15px;
}

.author {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #47556933;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff38;
  margin-top: 20px;
}

.author-left {
  display: flex;
  align-items: center;
}

.author-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.author-left-text h5 {
  color: #000000;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
}

.author-left-text p {
  display: flex;
  align-items: center;
  color: #1e293b;
  margin-bottom: 0;
}

.author-left-text p img {
  height: 15px;
  margin-right: 10px;
}

.author-right p {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.author-right p img {
  height: 20px;
  margin-right: 10px;
}

.p-price {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #d4d7db;
}

.p-price h4 {
  color: #1e293b;
  font-weight: 600;
  font-size: 1.6rem;
}

.p-price p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.p-price p img {
  margin-right: 5px;
}

.p-desc {
  padding: 15px 0;
  border-bottom: 1px solid #d4d7db;
}

.p-desc h5 {
  color: #1e293b;
  font-weight: 600;
}

.p-desc p {
  color: #3f3f46;
  margin-bottom: 0;
  font-size: 0.9rem;
}

.p-specs {
  color: #475569;
}

.p-spec {
  width: 100%;
  display: flex;
  margin-top: 15px;
  font-size: 0.9rem;
}

.p-spec strong {
  width: 50%;
  font-weight: 600;
}

.p-spec span {
  width: 50%;
}

.p-terms {
  padding: 15px 0;
  font-size: 0.9rem;
  color: #3f3f46;
  border-bottom: 1px solid #d4d7db;
}

.p-terms a {
  color: #059669;
}

.p-shipping,
.p-payment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #d4d7db;
}

.p-shipping h5 {
  color: #1e293b;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
}

.p-payment h5 {
  color: #1e293b;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 600;
}

.p-options {
  display: flex;
  align-items: center;
}

.p-options img {
  height: 25px;
  cursor: pointer;
  transition: 0.5s;
}

.p-options img:not(:last-child) {
  margin-right: 10px;
}

.p-options img:hover {
  transform: translateY(-3px);
}

.p-buy {
  width: 100%;
  height: 45px;
  background-color: #059669;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
  margin-top: 15px;
}

.p-btn {
  width: 100%;
  height: 45px;
  margin-top: 15px;
  color: #1e293b;
  border: 1px solid #475569;
  border-radius: 5px;
  font-weight: 600;
}

.p-btn img {
  height: 25px;
  margin-right: 5px;
}

.qa-container {
  background-color: #ffffff;
  border-radius: 10px;
}

.qa-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.qa-heading h5 {
  margin-bottom: 0;
  color: #334155;
}

.qa-right {
  display: flex;
  align-items: center;
}

.qa-right a {
  color: #334155;
}

.qa-search {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #94a3b8;
  border-radius: 7px;
  overflow: hidden;
  width: 150px;
}

.qa-search img {
  height: 20px;
  margin: 0 10px;
}

.qa-search input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.8rem;
}

.qa-plus {
  margin-left: 10px;
}

.qa-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #d4d7db;
}

.qa-item-left h6 {
  color: #334155;
  margin-bottom: 0;
  font-weight: 600;
}

.qa-user {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.qa-user h5 {
  color: #334155;
  font-size: 1rem;
}

.qa-user h6 {
  font-size: 0.9rem;
}

.qa-user img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.qa-item-right p {
  color: #94a3b8;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.qa-item-right a {
  color: #059669;
  font-size: 0.9rem;
}

.qa-item-right a:hover {
  text-decoration: underline;
}

.review-modal-top-left h1 {
  font-size: 4rem;
  font-weight: 400;
  text-align: center;
  color: #059669;
}

.review-modal-top-left div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-modal-top-left div img {
  margin: 0 3px;
  height: 15px;
}

.review-modal-top-left p {
  margin-bottom: 0;
  color: #64748b;
  text-align: center;
  width: 100%;
}

.review-modal-top-right {
  flex: 1;
  margin-left: 20px;
}

.star-item {
  width: 100%;
  display: flex;
  align-items: center;
  color: #334155;
}

.star-item {
  margin-bottom: 10px;
}

.star-item:last-child {
  margin-bottom: 0;
}

.star-item img {
  margin-right: 5px;
}

.star-pourcentage {
  flex: 1;
  height: 25px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  color: #64748b;
  background-color: #f1f2f6;
  border-radius: 5px;
  margin-left: 5px;
  padding: 0 5px;
}

.review-modal-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px 0;
}

.review-modal-center-item {
  text-align: center;
  margin-top: 20px;
}

.review-modal-center-item .circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.review-modal-center-item .circle-center {
  color: #64748b;
  font-size: 0.8rem;
  width: calc(100% - 13px);
  height: calc(100% - 13px);
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.review-modal-center-item p {
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #334155;
}

.review-modal-btn {
  height: 50px;
  width: 100%;
  color: #ffffff;
  background-color: #059669;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.review-modal-btn:hover {
  color: #ffffff;
}

.message-modal-input {
  width: 100%;
  height: 200px;
  outline: none;
  border: 2px dashed #94a3b8;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 15px;
}

.message-modal-btn {
  width: 100%;
  height: 45px;
  background-color: #059669;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
}

.offer-modal-toggle {
  width: 100%;
  display: flex;
  height: 55px;
  border-radius: 8px;
  overflow: hidden;
}

.offer-modal-toggle button {
  width: 50%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #f1f5f9;
  color: #334155;
  font-weight: 600;
}

.offer-modal-toggle button.active {
  background-color: #6fd1b1;
  color: #ffffff;
}

.offer-modal-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e2e8f0;
}

.offer-modal-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.offer-modal-item-text {
  flex: 1;
}

.offer-modal-item-text h5 {
  font-weight: 600;
  color: #334155;
}

.offer-modal-item-text p {
  color: #64748b;
  margin-bottom: 0;
}

.offer-modal-price {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.offer-modal-price p {
  color: #334155;
  margin-bottom: 0;
  font-weight: 600;
}

.offer-modal-text {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.offer-modal-text .form-input {
  flex: 1;
  margin-left: 20px;
  padding: 0 10px;
}

.offer-modal-text input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: #475569;
}

.offer-modal-nums {
  display: flex;
  justify-content: flex-end;
}

.offer-modal-nums button {
  border: none;
  outline: none;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  background-color: #f1f5f9;
  color: #334155;
  font-weight: 600;
}

.offer-modal-nums button:not(:last-child) {
  margin-right: 10px;
}

.offer-modal-btn {
  border: none;
  outline: none;
  width: 100%;
  height: 45px;
  color: #ffffff;
  background-color: #059669;
  border-radius: 5px;
}

.offer-modal-item-price {
  color: #334155;
  font-weight: 600;
}

.offer-modal-item-btn {
  display: flex;
  align-items: flex-end;
}

.offer-modal-item-btn button {
  font-size: 0.8rem;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #059669;
  color: #ffffff;
  border: 1px solid #059669;
  margin-top: 35px;
}

.offer-modal-user {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e2e8f0;
}

.offer-modal-user img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.offer-modal-user-text {
  flex: 1;
}

.offer-modal-user-text h5 {
  font-weight: 600;
  color: #334155;
}

.offer-modal-user-text p {
  color: #1e293b;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.offer-modal-user-text p img {
  height: 15px;
  width: auto;
}

.offer-modal-heading {
  text-align: center;
  color: #1e293b;
  margin: 10px 0;
}

.reviews-heading {
  font-weight: 600;
}

.reviews-heading span {
  color: #059669;
}

.reviews-product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0 10px 0px 10px;
}

.reviews-product-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.review-product .reviews-modal-top {
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reviews-product .review-modal-top-right {
  flex: none;
}

.reviews-product .review-modal-top-right .star-pourcentage {
  width: 190px;
}

.reviews-product .review-modal-center {
  width: fit-content;
  margin-bottom: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reviews-product .review-modal-center .review-modal-center-item {
  margin: 0 10px;
}

.review-card {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  margin-top: 20px;
}

.review-card-top {
  display: flex;
  align-items: center;
}

.review-card-top img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.review-card-top-text {
  flex: 1;
}

.review-card-top-text p {
  margin-bottom: 0 !important;
  font-weight: 600;
  color: #334155;
  line-height: 15px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 165px; /* Adjust the max-width to fit your needs */
}

.review-card-top-text span {
  color: #64748b;
  font-size: 0.8rem;
  margin: 0;
}

.review-card-text {
  font-size: 0.9rem;
  margin-top: 10px;
}

.review-card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.review-card-bottom p {
  margin-bottom: 0;
}

.review-card-date {
  color: #64748b;
  font-size: 0.8rem;
}

.review-card-stars {
  display: flex;
}

.review-card-stars img:not(:last-child) {
  margin-right: 5px;
}

.register {
  width: 100%;
  background-image: url(./assets/images/footerBg.svg);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: top;
}

.register-heading {
  margin: 70px 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 2rem;
}

.register-cards {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.register-card {
  width: 150px;
  border-radius: 8px;
  padding: 15px;
  background-color: #ffffff;
  margin-top: 20px;
}

.register-card img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.register-card h5 {
  text-align: center;
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 5px;
}

.register-card span {
  width: 100%;
  text-align: center;
  display: block;
  font-size: 0.8rem;
}

.register-form {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  margin: 50px 0;
}

.register-form-heading {
  font-weight: 600;
}

.register-form h5 {
  font-weight: 600;
}

.register-form h5 a {
  color: #059669;
}

.register-col {
  margin-top: 10px;
}

.register-col label {
  font-size: 0.9rem;
  font-weight: 400;
}

.register-field {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #94a3b8;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
}

.register-field img {
  width: 18px;
  margin: 0 10px;
}

.register-field input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  background: none;
  font-size: 0.8rem;
}

.register-check label {
  margin-bottom: 0;
  margin-left: 10px;
  flex: 1;
  user-select: none;
  cursor: pointer;
}

.register-check label a {
  color: #059669;
}

.register-form .form-check-input {
  background-color: #d9d9d9 !important;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
}

.register-form .form-check-input:checked {
  background-color: #059669 !important;
}

.register-submit {
  width: 100%;
  height: 45px;
  background-color: #059669;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.register-terms {
  width: 100%;
  text-align: center;
}

.register-terms a {
  color: #059669;
}

.register-methods img {
  height: 40px;
  transition: 0.5s;
}

.register-methods img:hover {
  transform: translateY(-5px);
}

.items-filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}

.items-filter {
  margin-top: 10px;
}

.items-filter:not(:last-child) {
  margin-right: 10px;
}

.items-filter .dropdown-menu {
  padding: 0;
  overflow: hidden;
}

.items-filter .dropdown-menu li {
  padding: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.items-filter .dropdown-menu li:hover {
  background-color: #f1f2f6;
}

.items-filter-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  border: 1px solid #475569;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 0;
}

.items-filter-btn .d-search-img {
  height: 15px;
  margin: 0 5px;
}

.items-filter-btn .d-down-img {
  height: 7px;
  margin: 0 10px;
}

.items-filter-btn input {
  height: 100%;
  width: 100px;
  font-size: 0.8rem;
  border: none;
  outline: none;
  background: none;
  border-right: 1px solid #94a3b8;
}

.selected-filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #a3a3a3;
  padding-bottom: 10px;
}

.selected-filter {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 25px;
  border: 1px solid #475569;
  border-radius: 15px;
  background-color: #ffffff;
  font-size: 0.8rem;
  color: #525252;
  padding: 0 10px;
  margin-bottom: 10px;
}

.selected-filter:not(:last-child) {
  margin-right: 10px;
}

.selected-filter img {
  height: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.items-heading {
  font-weight: 400;
}

.item-card {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.item-card-img {
  width: 100%;
  display: block;
  position: relative;
}

.item-card-img img {
  width: 100%;
}

.item-card-status {
  position: absolute;
  top: 15px;
  left: 0;
  height: 30px;
  color: #ffffff;
  border-radius: 0 20px 20px 0;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.item-card-status.sold {
  background-color: #6fd1b180;
}
.item-card-status.featured {
  background-color: #fe522080;
}

.item-card-like {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;
}

.item-card-like img {
  height: 17px;
}

.item-card-text {
  padding: 10px;
}

.item-card-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-card-name h5 {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
}

.item-card-name div {
  display: flex;
  align-items: center;
}

.item-card-name div img {
  height: 20px;
  margin-right: 5px;
}

.item-card-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-card-price h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.item-card-price span {
  color: #475569;
  font-size: 0.8rem;
}

.item-card-brand {
  font-size: 0.8rem;
  color: #475569;
}

.item-card-brand span {
  font-weight: 600;
}

.item-card-tags {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.item-card-tag {
  font-size: 0.8rem;
  color: #ffffff;
  height: 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin-top: 5px;
}

.item-card-tag:not(:last-child) {
  margin-right: 5px;
}

.item-card-tag.available {
  background-color: #22c55e;
}
.item-card-tag.exchange {
  background-color: #3b82f6;
}

.item-card-tag.both {
  background-color: #70a6f8;
}

.paginations {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 30px 0;
}

.paginations a {
  display: block;
  font-weight: 600;
  margin: 2px 10px;
  color: #000000;
}

.paginations .active {
  background-color: #059669;
  color: white;
  text-align: center;
}

.paginations a img {
  height: 20px;
  margin-bottom: 3px;
}

.sort {
  font-size: 0.8rem;
  margin: 0 10px;
}

.profile-sidebar {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.sidebar-heading {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-weight: 600;
}

.sidebar-heading img {
  margin-right: 10px;
  height: 18px;
}

.sidebar-links {
  width: 100%;
}

.sidebar-links a {
  color: #017651;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.activeSideBar {
  /* animation: left-right 0.2s linear alternate; */
  background-color: #017651;
  color: white !important;
  padding: 8px 0px 8px 5px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.sidebar-links a img {
  width: 20px;
  margin-right: 10px;
}

/* .sidebar-links a:hover {
  background-color: #017651;
  color: white;
  padding: 8px 0px 8px 5px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
} */

/* @keyframes left-right {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(3%);
  }
} */

.edit-profile {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.edit-heading {
  font-weight: 600;
}

.form-field {
  width: 100%;
  margin-top: 15px;
}

.form-field label {
  display: block;
  color: #475569;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #475569;
}

.form-input {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  overflow: hidden;
}

.form-field input[type="text"],
.form-field input[type="password"],
.form-field input[type="number"] {
  flex: 1;
  height: 100%;
  font-size: 0.9rem;
  padding: 0 10px;
  outline: none;
  border: none;
}

.form-field input::placeholder {
  color: #94a3b8;
}

.form-field input[type="checkbox"] {
  margin: 0 10px;
  cursor: pointer;
}

.form-field input[type="checkbox"]:checked {
  background-color: #6fd1b1;
  border: 1px solid #6fd1b1;
}

.form-field select {
  width: 100%;
  height: 40px;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  color: #94a3b8;
  padding: 0 10px;
  font-size: 0.9rem;
  outline: none;
}

.edit-img-container {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 10px auto;
}

.edit-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
}

.edit-img-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 45px;
}

.edit-img-container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-cancel {
  height: 40px;
  padding: 0 20px;
  border: 1px solid gray;
  color: gray;
  margin-right: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 0.9rem;
  transition: 0.5s;
}

.form-cancel:hover {
  color: #ffffff;
  background-color: gray;
}

.form-save {
  height: 40px;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #059669;
  border: 1px solid #059669;
  color: #ffffff;
  font-size: 0.9rem;
}

.orders-top {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.orders-top h3 {
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 5px;
}

.orders-top p {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #64748b;
}

.orders-container {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 10px;
  margin-top: 15px;
}

.orders-container-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #000000;
}

.checkout-heading {
  width: 100%;
}

.checkout-heading h3 {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #334155;
}

.checkout-heading h3 img {
  height: 30px;
  margin-right: 10px;
}

.checkout-heading p {
  color: #64748b;
  font-size: 0.9rem;
}

.billing-heading {
  font-weight: 600;
  color: #334155;
  margin-top: 30px;
}

.billing-card {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.billing-card label {
  color: #64748b;
  font-size: 0.8rem;
}

.billing-title {
  color: #334155;
  font-weight: 600;
}

.form-country {
  color: #94a3b8;
  display: flex;
  align-items: center;
  margin: 5px;
  padding-right: 10px;
  border-right: 1px solid #94a3b8;
}

.form-country img {
  height: 25px;
  margin-right: 5px;
}

.payment-method {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s;
}

.payment-method img {
  height: 30px;
}

.payment-method.active {
  background-color: #cbd5e1;
}

.cart-items {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.cart-items-heading {
  font-weight: 600;
}

.cart-items-number {
  color: #64748b;
  font-size: 0.8rem;
  font-weight: 600;
}

.cart-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e2e8f0;
}

.cart-item-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}

.cart-item-text {
  flex: 1;
}

.cart-item-text h5 {
  color: #334155;
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 600;
}

.cart-item-text p {
  color: #64748b;
  font-size: 0.8rem;
}

.cart-item-text h6 {
  color: #334155;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
}

.cart-item-options {
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}

.cart-item-options img {
  height: 20px;
  cursor: pointer;
}

.cart-item-num {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #475569;
  font-weight: 500;
}

.cart-item-num span {
  background-color: #f1f5f9;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.cart-specs {
  width: 100%;
  margin: 20px 0;
}

.cart-spec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #64748b;
  margin: 10px 0;
  font-weight: 600;
}

.cart-spec span {
  color: #334155;
}

.discount-heading {
  display: block;
  font-weight: 600;
  font-size: 0.8rem;
  color: #334155;
  margin-bottom: 5px;
}

.discount-field {
  width: 100%;
  display: flex;
  height: 35px;
  font-size: 0.9rem;
}

.discount-field input {
  flex: 1;
  height: 100%;
  border: 1px solid #475569;
  border-radius: 5px;
  margin-right: 10px;
  outline: none;
}

.discount-field button {
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #059669;
  height: 100%;
  padding: 0 20px;
  border-radius: 5px;
}

.checkout-submit {
  width: 100%;
  height: 50px;
  background-color: #059669;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-submit img {
  height: 15px;
  margin-left: 10px;
}

.ads-top {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.ads-top-left {
  flex: 1;
}

.ads-top-left h5 {
  color: #1e293b;
  font-weight: 600;
}

.ads-top-left p {
  margin-bottom: 0;
  color: #94a3b8;
  font-size: 0.9rem;
}

.ads-top a {
  height: 40px;
  background-color: #059669;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 0 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.ad-item-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #94a3b8;
  padding-bottom: 10px;
}

.ad-item-top p {
  margin-bottom: 5px;
  color: #94a3b8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-item-top p img {
  height: 20px;
  margin-right: 10px;
}

.ad-top-right {
  display: flex;
  align-items: center;
}

.ad-top-right button {
  height: 40px;
  padding: 0 20px;
  outline: none;
  border-radius: 5px;
  margin-bottom: 5px;
}

.ad-top-right .dropdown-menu li {
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.ad-top-right .dropdown-menu li:hover {
  background-color: #f1f5f9;
}

.result-btn {
  background-color: #059669;
  color: #ffffff;
  border: 1px solid #059669;
  margin-right: 10px;
}

.dots-btn {
  background-color: #ffffff;
  border: 1px solid gray;
}

.ad-details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ad-details img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 20px;
}

.ad-item-details {
  margin-top: 20px;
}

.ad-item-details h5 {
  color: #1e293b;
  font-weight: 600;
  text-align: center;
}

.ad-item-details p {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  color: #94a3b8;
  font-size: 0.9rem;
}

.ad-item-details ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.ad-item-details ul li {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}

.ad-item-details ul li:hover {
  box-shadow: 0 0.5rem 1rem #00000026 !important;
}

.ad-item-details ul li:not(:last-child) {
  margin-right: 5px;
}

.ad-more {
  color: #1e293b;
  font-weight: 500;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.ad-more a {
  color: #059669;
}

.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.profile-img img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profile-img h5 {
  color: #1e293b;
  font-weight: 600;
}

.profile-img p {
  color: #475569;
  font-size: 0.9rem;
}

.profile-img h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #525252;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.profile-infos {
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px;
}

.infos-left h5 {
  color: #525252;
  font-weight: 600;
  margin-bottom: 10px;
}

.infos-left p {
  color: #475569;
  margin-bottom: 10px;
}

.infos-pourcentage {
  display: flex;
  align-items: center;
}

.pourcentage-item {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pourcentage-item:not(:last-child) h1 {
  border-right: 1px solid #f1f2f6;
}

.pourcentage-item h1 {
  color: #525252;
  padding: 0 10px;
}

.pourcentage-item h5 {
  color: #525252;
  padding: 0 10px;
}

.pourcentage-item p {
  margin-bottom: 0;
  color: #a3a3a3;
}

.infos-left.infos-2 p {
  font-weight: 600;
  color: #475569;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.infos-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.infos-btns button {
  height: 35px;
  background-color: #059669;
  padding: 0 20px;
  font-size: 0.9rem;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
}

.infos-btns-profile {
  width: 100%;
  align-items: center;
}

.infos-btns-profile button {
  height: 35px;
  background-color: #059669;
  padding: 0 20px;
  font-size: 0.9rem;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
}

.orders-container-top.profile-products {
  margin-top: 5px;
}

.orders-container-top.profile-products h5 {
  font-weight: 600;
}

.orders-container-top.profile-products a {
  color: #059669;
  font-weight: 600;
}

.messages {
  border-radius: 10px;
  background-color: #ffffff;
}

.messages-list {
  padding: 20px;
  border-right: 1px solid #e2e8f0;
}

.messages-heading {
  font-size: 1rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 20px;
}

.messages-input {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #94a3b8;
  border-radius: 5px;
  padding: 0 10px;
  background-color: #ffffff;
  margin-bottom: 20px;
}

.messages-input input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9rem;
  background: none;
}

.messages-input img {
  height: 15px;
  margin-right: 10px;
}

.users-list {
  width: 100%;
}

.chat-user {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  transition: 0.5s;
}

.chat-user:hover {
  filter: brightness(0.7);
}

.chat-user img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

.chat-user-text {
  flex: 1;
}

.chat-user-text h5 {
  color: #334155;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
  width: 75%;
}

.chat-user-text p {
  margin-bottom: 0;
  color: #94a3b8;
  font-size: 0.7rem;
}

.chat-user .chat-time {
  position: absolute;
  top: 10px;
  right: 0;
  color: #64748b;
  font-size: 0.6rem;
}

.chat-container {
  padding: 20px;
}

.chat-top {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e2e8f0;
}

.chat-top img {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 10px;
}

.chat-top-text {
  flex: 1;
}

.chat-top-text h5 {
  color: #334155;
  font-weight: 600;
}

.chat-top-text p {
  color: #64748b;
  font-size: 0.9rem;
  margin-bottom: 0;
}

.chat {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.chat-heading {
  margin: 20px 0;
  color: #334155;
  font-weight: 600;
}

.chat-message {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.chat-message.user1 {
  justify-content: flex-end;
}

.chat-message.user2 {
  justify-content: flex-start;
}

.chat-message img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.chat-message p {
  width: 50%;
  margin: 0 10px;
  margin-bottom: 0;
  font-size: 0.8rem;
  padding: 10px;
}

.chat-message span {
  color: #94a3b8;
  font-size: 0.8rem;
  margin-top: 5px;
}

.chat-message.user2 p {
  color: #334155;
  background-color: #f1f2f6;
  border-radius: 0 20px 20px 20px;
}

.chat-message.user1 p {
  color: #ffffff;
  background-color: #6fd1b1;
  border-radius: 20px 0 20px 20px;
}

.chat-field {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #94a3b8;
  border-radius: 10px;
  height: 45px;
}

.chat-field img {
  height: 25px;
  margin: 0 10px;
  cursor: pointer;
}

.chat-field input {
  flex: 1;
  height: 100%;
  background: none;
  border: none;
  outline: none;
}

.create-item-heading {
  font-weight: 600;
}

.create-item-form {
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.drop-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #38c39514;
  border: 2px dashed #38c395;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  transition: 0.5s;
}

.drop-file:hover {
  background-color: #38c39533;
}

.drop-file img {
  height: 40px;
  margin-bottom: 10px;
}

.drop-file h5 {
  color: #1e293b;
  font-weight: 600;
  margin-bottom: 10px;
}

.drop-file h5 span {
  color: #37c294;
}

.drop-file h6 {
  font-size: 0.9rem;
  color: #64748b;
  margin-bottom: 0;
  font-weight: 400;
}

.drop-file input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-textarea {
  height: auto;
}

.form-field textarea {
  flex: 1;
  height: 200px;
  font-size: 0.9rem;
  padding: 10px;
  outline: none;
  border: none;
}

.form-field-profile textarea {
  flex: 1;
  height: 100px;
  font-size: 0.9rem;
  padding: 10px;
  outline: none;
  border: none;
}

.form-field textarea::placeholder {
  color: #94a3b8;
}

.terms-card {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.terms-card h5 {
  font-weight: 600;
  color: #334155;
}

.terms-card p {
  color: #64748b;
  font-size: 0.9rem;
}

.hover-shadow {
  transition: 0.5s;
}

.hover-shadow:hover {
  box-shadow: 0 0.5rem 1rem #00000026 !important;
}

.green-btn {
  transition: 0.5s;
}

.green-btn:hover {
  background-color: #017651;
}

.country-dropdown-btn {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.country-dropdown-left {
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: 1;
}

.country-dropdown-left img {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.country-dropdown-menu {
  width: 100%;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}

.country-dropdown-menu li {
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.5s;
  color: #059669;
  font-weight: 600;
}

.country-dropdown-menu li:hover {
  background-color: #f1f2f6;
}

.country-dropdown-menu li img {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.language-dropdown {
  margin-right: 0;
  margin-left: 15px;
  outline: none;
  cursor: pointer;
  padding: 0 10px;
  border-color: #059669;
  color: #059669;
}

.language-dropdown-2 {
  outline: none;
  cursor: pointer;
  padding: 0 10px;
  border-color: #059669;
  color: #059669;
}

.language-dropdown-2:hover {
  background-color: #059669;
  color: #ffffff;
}

.form-cancel-2 {
  width: 150px;
  height: 37px;
  padding: 0px 5px;
  color: gray;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 0.9rem;
  transition: 0.5s;
}

.language-dropdown:hover {
  background-color: #059669;
  color: #ffffff;
}

.product-img-container {
  position: relative;
  display: block;
  height: 330px;
  border-radius: 5px;
  overflow: hidden;
}

.product-ribbon {
  position: absolute;
  top: 10px;
  left: 0;
  color: #ffffff;
  padding: 10px 0;
  width: 100px;
  border-radius: 0 20px 20px 0;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.product-quantity {
  position: absolute;
  top: 10px;
  right: 0;
  color: #ffffff;
  padding: 10px 0;
  width: 100px;
  border-radius: 20px 0px 0px 20px;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
  z-index: 2;
  background-color: #000;
}

.product-ribbon.standard {
  background-color: #01765190;
}

.product-ribbon.urgent {
  background-color: #daa52094;
}

.product-ribbon.featured {
  background-color: #5cafea94;
}

.product-ribbon.sold {
  background-color: #7c050594;
}

.message-modal-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message-modal-top img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.message-modal-top-text h6 {
  font-weight: 600;
}

.message-modal-top-text div {
  display: flex;
  align-items: center;
}

.message-modal-top-text div img {
  height: 15px;
  width: auto;
  margin-right: 5px;
}

.message-modal-top-text div span {
  font-size: 0.8rem;
  color: #a3a3a3;
}

.reviews-modal-item {
  border-bottom: 1px solid #cbcbcb;
  margin-top: 10px;
}

.reviews-modal-item-top {
  display: flex;
  justify-content: space-between;
}

.reviews-modal-item-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.reviews-modal-item-left .reviews-modal-user {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.reviews-modal-item-left-text h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #334155;
}

.reviews-modal-item-left-text div {
  display: flex;
  align-items: center;
}

.reviews-modal-item-left-text div img {
  height: 15px;
  margin-right: 5px;
}

.reviews-modal-item-right {
  font-size: 0.8rem;
  color: gray;
  font-weight: 600;
}

.reviews-modal-item-bottom {
  color: gray;
  font-size: 0.8rem;
  margin: 10px 0;
}

.success-message {
  text-align: center;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.success-message h1 {
  color: #059669;
  font-size: 5rem;
  margin-bottom: 20px;
}

.success-message h3 {
  color: #334155;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.profile-dropdown button {
  border: none;
  outline: none;
  height: 40px;
  display: flex;
  align-items: center;
  background: none;
  font-size: 0.9rem;
  margin-left: 15px;
}

.profile-dropdown img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 5px;
}

.profile-dropdown ul {
  padding: 0;
}

.profile-dropdown li a {
  padding: 5px 10px;
  color: #000000;
  display: block;
  transition: 0.5s;
}

.profile-dropdown li a:hover {
  background-color: #f1f2f6;
}

.ads-tabs {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.ads-tabs button {
  border: none;
  outline: none;
  background: none;
  padding: 10px;
  color: #059669;
  border-bottom: 1px solid transparent;
}

.ads-tabs button.active {
  border-bottom: 1px solid #059669;
}

.add-feedback-btn {
  border: none;
  outline: none;
  background-color: #059669;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
}

.view-invoice-btn {
  border: none;
  outline: none;
  background-color: #a3a3a3;
  font-size: 0.8rem;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 0.5s;
}

.view-invoice-btn:hover {
  color: #ffffff;
  background-color: #8f8f8f;
}

.donate-check .form-check-input {
  background-color: #d9d9d9 !important;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.donate-check .form-check-input:checked {
  background-color: #059669 !important;
}

.donate-check label {
  cursor: pointer;
  color: #94a3b8;
}

.my-review {
  position: relative;
}

.my-review .hide-check {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  color: #475569;
}

.my-review .form-check-input {
  background-color: #d9d9d9 !important;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  margin-left: 5px;
}

.my-review .form-check-input:checked {
  background-color: #059669 !important;
}

.withdraw-title {
  font-weight: 600;
  margin-bottom: 20px;
  color: #525252;
}

.withdraw-table td,
.withdraw-table th {
  min-width: 150px;
}

.points-heading h5,
.points-heading h3 {
  font-weight: 600;
  color: #334155;
}

.point-card {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.point-card h6 {
  margin-bottom: 0;
  flex: 1;
  margin-right: 20px;
  color: #334155;
  font-weight: 400;
}

.point-card h6 span {
  color: #334155;
  font-weight: 700;
}

.point-card span {
  color: #a3a3a3;
  font-size: 0.9rem;
}

.view-orders-btn {
  height: 50px;
  border-radius: 5px;
  padding: 0 20px;
  background-color: #059669;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.rating-box {
  display: inline-block;
}
.rating-container {
  direction: rtl !important;
}
.rating-container label {
  display: inline-block;
  color: #d4d4d4;
  cursor: pointer;
  font-size: 2rem;
  transition: color 0.2s;
}
.rating-container input {
  display: none;
}
.rating-container label:hover,
.rating-container label:hover ~ label,
.rating-container input:checked ~ label {
  color: gold;
}

.form-radios input {
  border-color: #059669;
}

.form-radios input:checked {
  background-color: #059669;
}

.form-radios label {
  margin-top: 2px;
}

.invoice-product {
  width: 100%;
  padding: 20px 20px 0 20px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.invoice-product img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.invoice-product-text {
  margin-bottom: 20px;
  flex: 1;
}

.invoice-product-text h5 {
  color: #334155;
  font-weight: 600;
}

.invoice-product-text h6 {
  color: #64748b;
}

.invoice-product-text h6 strong {
  color: #334155;
}

.invoice-information {
  padding: 10px;
}

.invoice-information h5 {
  font-weight: 600;
  margin-bottom: 20px;
  color: #334155;
}

.form-multiple-select {
  position: relative;
}

.form-multiple-select button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 10px;
  font-size: 0.9rem;
  color: #94a3b8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
}

.form-multiple-select ul {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 2px solid #cbd5e1;
  border-radius: 8px;
  list-style: none;
  padding: 0;
}

.form-multiple-select ul li {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 0.9rem;
  color: #475569;
  cursor: pointer;
  transition: 0.5s;
}

.form-multiple-select ul li:hover {
  background-color: #f5f5f5;
}

.invoice-infos {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}

.invoice-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #475569;
  margin-bottom: 10px;
}

.invoice-info span {
  font-weight: 600;
}

/* Loading Spiner start */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loading Spiner end */

/* Cart Animate start */

.cart-animate {
  animation: up-and-down 0.4s linear infinite alternate;
}
@keyframes up-and-down {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-15%);
  }
}
/* Cart Animate end */
/* ADMIN DASHBOARD CSS */
:root {
  --primary-color: #017651;
  --bg-color: #dfe6e9;
  --text-color: white;
}

.container-card {
  background: white;
  cursor: pointer;
  border-radius: 15px;
  position: relative;
  padding: 25px 30px;
  width: 100%;
  color: var(--primary-color);
  /* animation: up-and-down 1.25s linear infinite alternate; */
}

.container-card::after {
  content: "";
  background: var(--primary-color);
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  clip-path: circle(10% at 0% 0%);
  transition: all 0.3s ease-in;
}

.content {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in;
}

.container-card:hover::after {
  clip-path: circle(100%);
  /* animation: up-and-down 0.4s linear infinite alternate; */
}

.container-card:hover .content {
  color: var(--text-color);
}

.cust-img {
  width: 50px;
  height: 50px;
}

.shop-table td,
.shop-table th {
  min-width: 120px;
}

@keyframes up-and-down {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-15%);
  }
}

/* .accordion-button:not(.collapsed) {
  color: var(--primary-color);
}

.accordion-item {
  color: var(--primary-color);
}

.accordion-header {
  background: var(--primary-color) !important;
} */
.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  box-shadow: var(--primary-color);
  color: white !important;
}
.accordion-button::after {
  color: white !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: white !important;
}

/* Add Category Image start*/

.cat-img {
  max-width: 70% !important;
  max-height: 65% !important;
}

.cate-card {
  max-height: 155px !important;
}

.cate-card img {
  max-width: 100% !important;
  max-height: 65% !important;
}

/* Add Category Image end*/

/* ADMIN DASHBOARD CSS END*/

.loading {
  margin-right: 20px;
  margin-bottom: 60px;
}
.loading span {
  float: right;
  width: 1em;
  height: 1em;
  margin: 0.19em;
  background: #e2e1e1;
  border-radius: 0.6em;
  animation: loading 1s infinite alternate;
}

.loading span:nth-of-type(2) {
  background: #e2e1e1;
  animation-delay: 0.2s;
}
.loading span:nth-of-type(3) {
  background: #e2e1e1;
  animation-delay: 0.4s;
}
.loading span:nth-of-type(4) {
  background: #e2e1e1;
  animation-delay: 0.6s;
}
.loading span:nth-of-type(5) {
  background: #e2e1e1;
  animation-delay: 0.8s;
}
.loading span:nth-of-type(6) {
  background: #e2e1e1;
  animation-delay: 1s;
}
.loading span:nth-of-type(7) {
  background: #e2e1e1;
  animation-delay: 1.2s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fs-8rem {
  font-size: 0.8rem;
}

.description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.description.expanded {
  -webkit-line-clamp: unset;
}

.app-bg {
  background-color: #f8f4f4;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: #059669;
  transform-origin: 0%;
  z-index: 9999;
}

.card-height {
  min-height: 172px !important;
}

/* filter style */
.filter-width {
  width: 150px !important;
}

.header-search-card {
  left: 39.5%;
  top: 9%;
  width: 287px;
  z-index: 9999;
  overflow-y: auto;
  min-height: 220px !important;
  overflow-x: hidden;
}

.dropdown-noti {
  display: inline-block;
  margin-left: 20px;
  /* padding: 10px; */
}
/* position: "absolute",
fontSize: "8px !important",
background: "red",
color: "white",
bordeRadius: "50%",
right: "120px !important",
top: "27px !important",
width: "15px !important", */

.noti-badge {
  position: absolute;
  font-size: 8px !important;
  background-color: red !important;
  color: white !important;
  border-radius: 50%;
  right: 120px !important;
  top: 27px !important;
  width: 15px !important;
}

.notifications {
  min-width: 350px !important;
  max-width: 245px !important;
}

.notifications-wrapper {
  overflow: auto;
  max-height: 250px;
}

.glyphicon-circle-arrow-right {
  margin-left: 10px;
}

.notification-heading,
.notification-footer {
  padding: 2px 10px;
}

.dropdown-menu.divider {
  margin: 5px 0;
}

.item-title {
  font-size: 1.1rem;
  color: #474646;
}

.item-title-white {
  font-size: 1.1rem;
  color: #ffffff;
}

.item-info {
  font-weight: 500;
  font-size: 12px;
  color: #8b8a8a;
}

.item-info-white {
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
}

.notifications a.content {
  text-decoration: none;
  background: #ccc;
}

.notification-item {
  padding: 10px 0px 1px 10px;
  margin: 5px;
  background: #fff;
  border-radius: 4px;
  border-bottom: 1px solid #b9b9b9;
}

@media only screen and (max-width: 1650px) and (min-width: 1250px) {
  .header-search-card {
    left: 39%;
    top: 11%;
    width: 282px;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 1100px) {
  .header-search-card {
    left: 37.5%;
    top: 11%;
    width: 275px;
  }
  .noti-badge {
    right: 60px !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 1100px) {
  .header-search-card {
    left: 39.5% !important;
    top: 11%;
    width: 285px !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .header-search-card {
    left: 37% !important;
    top: 10.5% !important;
    width: 300px !important;
  }
  .noti-badge {
    right: 5px !important;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1000px) {
  .header-search-card {
    left: 37% !important;
    top: 10% !important;
    width: 290px !important;
  }
  .noti-badge {
    right: 5px !important;
  }
}
